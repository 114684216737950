@import url('https://fonts.googleapis.com/css?family=Elsie|Montserrat|Lora|Tangerine&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #FFF;
}

html {
    scroll-behavior: smooth;
    overflow: scroll;
    overflow-x: hidden;
}

#root {
    padding-left: 2%;
    padding-right: 2%;
}

.high_class_text {
    font-family: 'Montserrat', sans-serif;
    font-size: calc(16px + (22 - 14) * ((100vw - 300px) / (2560 - 300)));
    line-height: calc(1.3em + (1.5 - 1.2) * ((100vw - 300px) / (2560 - 300)));
}

.quote {
    font-family: 'Tangerine', cursive;
    font-size: calc(14px + (50 - 20) * ((100vw - 300px) / (2560 - 300)));
    line-height: calc(1.3em + (1.5 - 1.2) * ((100vw - 300px) / (2560 - 300)));
}

.content_text {
    font-family: 'Lora', serif;
    text-decoration: 'none';
    color: #000;
    font-size: calc(14px + (20 - 12) * ((100vw - 300px) / (2560 - 300)));
    margin: auto;
}

body, caption, th, td, input, textarea, select, option, legend, fieldset, h1, h2, h3, h4, h5, h6 {
    font-size-adjust: 0.5;
}

a {
    text-decoration: none;
}

#controls {
    margin: 4px;
}

#controls:hover {
    cursor: pointer;
}

.slider-slide:focus {
   outline: none;
}
